<template>
  <div>
    <div class="row">
      <div class="col-sm-9">
        <h3 class="text-left">News/Announcement of Updates</h3>
      </div>
      <div class="col-sm-3 text-right">
        <button class="e-btn e-btn-green e-text-white pr-3 mt-2" @click="showCreateModal = true">
          <font-awesome-icon icon="plus-square" style="width: 25px"/>
          <small class="font-weight-bold">
            CREATE ANNOUNCEMENT
          </small>
        </button>
      </div>
    </div>


    <div class="row">
      <nav aria-label="...">
        <ul class="pagination text-smallest mb-2 pb-0">
          <li class="page-item" :class="{ 'disabled': pagination.currentPage === 1}">
            <button class="page-link" @click="setPage(1)">First</button>
          </li>

          <li v-for="page in paginationPages" :key="page" class="page-item" :class="{ 'disabled': page === '...', 'active': page === pagination.currentPage }">
            <button class="page-link" @click="setPage(page)">{{ page }}</button>
          </li>

          <li class="page-item" :class="{ 'disabled': pagination.currentPage === pagination.lastPage}">
            <button class="page-link" @click="setPage(pagination.lastPage)">Last</button>
          </li>
        </ul>
      </nav>
    </div>

    <div class="row">

      <table class="table table-sm table-bordered small">
        <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">TITLE</th>
          <th scope="col">BODY</th>
          <th scope="col">START DATE</th>
          <th scope="col">END DATE</th>
          <th scope="col">ACTION</th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="loading">
          <td colspan="100%">
            <loading></loading>
          </td>
        </tr>
        <template v-else>
          <template v-for="(announcement, index) in announcements">
            <tr>
              <th scope="row">{{ index + pagination.from }}</th>
              <td>{{ announcement.title }}</td>
              <td>{{ announcement.plain_text_body }}</td>
              <td>{{ announcement.start_date | moment('D MMM YYYY') }}</td>
              <td>{{ announcement.end_date | moment('D MMM YYYY') }}</td>
              <td class="text-white">
                <div class="btn-group dropleft">
                  <button
                    id="actionDropdown"
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="mr-2 btn btn-sm btn-secondary force-white-all"
                    data-toggle="dropdown"
                  >
                    <font-awesome-icon icon="cogs"/>
                  </button>
                  <div aria-labelledby="actionDropdown" class="dropdown-menu">
                    <button class="dropdown-item text-secondary font-weight-bolder" @click="handleUpdateModalOpen(announcement.id)">
                      <font-awesome-icon icon="pen"/>
                      Update
                    </button>
                    <button class="dropdown-item text-secondary font-weight-bolder text-danger"
                            @click="handleDeleteModalOpen(announcement.id)"
                    >
                      <font-awesome-icon icon="trash" class="force-danger-all"/>
                      Delete
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </template>
        </tbody>
      </table>
    </div>

    <BaseModal :is-visible="showCreateModal" @close="handleCreateModalClose" size="xl">
      <template v-slot:title>Create Announcement</template>

        <template v-slot:body>
          <form @submit.prevent="handleSubmit">
          <div class="form-group row">
            <label for="inputEmail3" class="col-sm-2 col-form-label">Title</label>
            <div class="col-sm-10">
              <input type="text" class="form-control" v-model="form.title">
            </div>
          </div>

          <div class="form-group row">
            <label for="inputEmail3" class="col-sm-2 col-form-label">Content</label>
            <div class="col-sm-10">
              <vue-editor class="my-2" :editor-toolbar="toolbar" v-model="form.body"/>
            </div>
          </div>

          <div class="form-group row">
            <label for="inputEmail3" class="col-sm-2 col-form-label">Attachments</label>
            <div class="col-sm-10">
              <file-pond
                :files="form.loadedFiles"
                id="create"
                name="file"
                ref="pond"
                allow-multiple="true"
                max-files="5"
                style-button-remove-item-position="right"
                allow-process="false"
                :server="serverOptions"
                :max-file-size="5000000"
                :accepted-file-types="['image/jpeg', 'image/png']"
                @processfile="handleUploadedFile"
                @processfilerevert="handleRevertFile"
              />
              <span>max file 5</span>
            </div>
          </div>

          <div class="form-group row">
            <label for="inputEmail3" class="col-sm-2 col-form-label">Start Date</label>
            <div class="col-sm-10">
              <date-picker id = "date-picker" lang="en" type="date" valueType="format" v-model="form.start_date"></date-picker>
            </div>
          </div>

          <div class="form-group row">
            <label for="inputEmail3" class="col-sm-2 col-form-label">End Date</label>
            <div class="col-sm-10">
              <date-picker id = "date-picker" lang="en" type="date" valueType="format" v-model="form.end_date"></date-picker>
            </div>
          </div>
          </form>
        </template>

        <template v-slot:footer>
<!--          <button type="button" class="btn btn-sm btn-secondary" @click="handleCreateModalClose">Cancel</button>-->
          <button type="submit" class="btn btn-sm btn-primary" @click="handleSubmit">Create</button>
        </template>

    </BaseModal>

    <BaseModal :is-visible="showUpdateModal" @close="handleUpdateModalClose" size="xl">
      <template v-slot:title>Update Announcement</template>

      <template v-slot:body>
        <form @submit.prevent="handleSubmit">
          <div class="form-group row">
            <label for="inputEmail3" class="col-sm-2 col-form-label">Title</label>
            <div class="col-sm-10">
              <input type="text" class="form-control" v-model="form.title">
            </div>
          </div>

          <div class="form-group row">
            <label for="inputEmail3" class="col-sm-2 col-form-label">Content</label>
            <div class="col-sm-10">
              <vue-editor class="my-2" :editor-toolbar="toolbar" v-model="form.body"/>
            </div>
          </div>

          <div class="form-group row">
            <label for="inputEmail3" class="col-sm-2 col-form-label">Attachments</label>
            <div class="col-sm-10">
              <file-pond
                :files="form.loadedFiles"
                id="update"
                name="file"
                ref="pond"
                allow-multiple="true"
                max-files="5"
                style-button-remove-item-position="right"
                allow-process="false"
                :server="serverOptions"
                :max-file-size="5000000"
                @processfilestart="onProcessFileStart"
                @processfileabort="onProcessFileAbort"
                @processfileerror="onProcessFileError"
                @processfile="handleUploadedFile"
                @processfilerevert="handleRevertFile"
                @removefile="handleRemoveFile"
              />
              <span>max file 5</span>
            </div>
          </div>

          <div class="form-group row">
            <label for="inputEmail3" class="col-sm-2 col-form-label">Start Date</label>
            <div class="col-sm-10">
              <date-picker id = "date-picker" lang="en" type="date" valueType="format" v-model="form.start_date"></date-picker>
            </div>
          </div>

          <div class="form-group row">
            <label for="inputEmail3" class="col-sm-2 col-form-label">End Date</label>
            <div class="col-sm-10">
              <date-picker id = "date-picker" lang="en" type="date" valueType="format" v-model="form.end_date"></date-picker>
            </div>
          </div>
        </form>
      </template>

      <template v-slot:footer>
<!--        <button type="button" class="btn btn-sm btn-secondary" @click="handleUpdateModalClose">Cancel</button>-->
        <button type="submit" class="btn btn-sm btn-primary" @click="handleUpdate()" :disabled="isUploading">Update</button>
      </template>

    </BaseModal>

    <BaseModal :isVisible="deleteModal" @close="deleteModal = false" :close-button="false">
      <template v-slot:title>DELETION OF ANNOUNCEMENT</template>
      <template v-slot:body>

        <p style="font-size: 13px;"> <b style="color:red">Note: This action cannot be undone.</b> Please enter your password to proceed.</p>
        <!--        <span style="font-size: 12px"><b>Please type your Password to Confirm.</b></span>-->
        <form @submit.prevent="handleDelete">
          <input type="password" v-model="password" class="form-control form-control-sm" placeholder="Enter Password" autocomplete="off">
        </form>
      </template>
      <template v-slot:footer>
<!--        <button type="button" class="btn btn-sm btn-secondary" @click="closeDeleteModal">Cancel</button>-->
        <button type="button" class="btn btn-sm btn-primary" @click="handleDelete">Confirm</button>
      </template>
    </BaseModal>

  </div>

</template>


<script>
import {mapActions, mapGetters} from "vuex";
import loading from "@/components/elements/AppLoading.vue";
import BaseModal from "@/components/common/BaseModal.vue";
import DatePicker from "vue2-datepicker";
import { PaginationMixin } from "@/mixins/PaginationMixin";
import {AnnouncementService} from "@/services/AnnouncementService";
import {AlertService} from "@/services/AlertService";
import 'filepond/dist/filepond.min.css';

import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import vueFilePond from "vue-filepond";
import {API_URL, FILE_POND_LOAD, FILE_POND_PROCESS, FILE_POND_REVERT} from "@/services/ConstantService";
import {VesselService} from "@/services/VesselService";

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

export default {
  components: {BaseModal, loading, DatePicker, FilePond},
  mixins: [PaginationMixin],
  data() {
    return {
      password: null,
      deleteModal: false,
      isUploading: false,
      idToUpdate: null,
      form : {
        title: '',
        body: '',
        files: [],
        loadedFiles: [],
        start_date: null,
        end_date: null,
        is_active: true,
      },
      loading: false,
      showCreateModal: false,
      showUpdateModal: false,
      filters: {
        page: 1
      },
      toolbar:[
        ['bold','italic','strike','underline'],
        [{'color':["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff",
            "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff",
            "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff",
            "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2",
            "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"]}]
      ],
      serverOptions: {
        load: {
          url: API_URL + FILE_POND_LOAD + '?file=',
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('kidsToken'),
          },
        },
        process: {
          url: API_URL + FILE_POND_PROCESS,
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('kidsToken'),
          },
        },
        revert: {
          url: API_URL + FILE_POND_REVERT, // Your API endpoint for revert
          method: "DELETE", // HTTP method (usually DELETE)
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('kidsToken'),
          },
        }
      },
    }
  },
  methods: {
    ...mapActions(['getAnnouncement', "getAnnouncementById"]),
    async init(){
      await this.getAnnouncement(this.filters)
    },
    handleRemoveFile(error, file) {
      const sources = this.form.loadedFiles.map(item => item.source)

      if(file.status === 2){
        // this.form.loadedFiles = this.form.loadedFiles.filter(f => f.source !== file.serverId)
        let index = this.form.loadedFiles.findIndex(f=>f.source === file.serverId);
        if(index >= 0){
          this.form.loadedFiles.splice(index,1);
        }
      }
    },
    async handleSubmit(){
      if(this.isProcessingFiles()) {
        return AlertService.warningAlert('Please wait for the files to finish processing', 'ATTACHMENTS PROCESSING');
      }
      swal.fire({title: 'Please wait'});
      swal.showLoading();
      const result = await AnnouncementService.create(this.form)
      if (result) {
        await this.getAnnouncement(this.filters)
        swal.close()
        AlertService.successAlert('Success to create announcement', 'CREATING ANNOUNCEMENT')
        this.handleCancel()
        this.showCreateModal = false
      }

    },
    handleUploadedFile(error, file){
      this.checkUploadsStatus();
      if (!error) {
        // Capture the UUID from the server response
        const uuid = file.serverId

        this.form.files.push(uuid)
      } else {
        console.error('File upload error:', error);
      }
    },
    async handleUpdate(){
      if(this.isProcessingFiles()) {
        return AlertService.warningAlert('Please wait for the files to finish processing', 'ATTACHMENTS PROCESSING');
      }
      swal.fire({title: 'Please wait'});
      swal.showLoading();
      const result = await AnnouncementService.update(this.announcementById.id, this.form)
      if (result) {
        await this.getAnnouncement(this.filters)
        swal.close()
        AlertService.successAlert('Success to create announcement', 'CREATING ANNOUNCEMENT')
        this.handleCancel()
        this.showUpdateModal = false
      }

    },
    closeDeleteModal(){
      this.deleteModal = false
      this.password = null
    },
    handleCancel(){
      this.form.title = ''
      this.form.body = ''
      this.form.start_date = null
      this.form.end_date = null
      this.form.is_active = true
      this.form.files = []
      this.form.loadedFiles = []
    },
    async deleteAnnouncement(id){
      swal.fire({title: 'Please wait'});
      swal.showLoading();
      const result = await AnnouncementService.delete(id)
      console.log(result)
      await this.getAnnouncement(this.filters)
      swal.close()
      AlertService.successAlert('Success to delete announcement', 'DELETING ANNOUNCEMENT')
    },
    setPage(page = 1) {
      this.pagination.currentPage = page;
      this.filters.page = page
      this.init()
    },
    handleRevertFile(file) {
      this.form.files = this.form.loadedFiles.filter(f => f !== file.serverId);
    },
    async handleDelete(){
      try {
        await AnnouncementService.delete(
          {
            id: this.announcementById.id,
            password: this.password
          }
        )
        this.deleteModal = false
        this.password = null
        AlertService.successAlert('File Deleted Successfully', 'FILE DELETION')
        await this.getAnnouncement(this.filters)
        this.handleCancel()
      } catch (e) {
        AlertService.errorAlert(e.message, 'FILE DELETION')
      }

    },

    async handleDeleteModalOpen(id){
      this.deleteModal = true
      await this.getAnnouncementById(id)
    },
    async handleUpdateModalOpen(id){
      this.showUpdateModal = true
      await this.getAnnouncementById(id)
      this.form.title = this.announcementById.title
      this.form.body = this.announcementById.body
      this.form.start_date = this.announcementById.start_date
      this.form.end_date = this.announcementById.end_date
      this.form.loadedFiles = this.announcementById.attachments.map((attachment) => {
        return {
          source: attachment.path,
          options: {
            type: 'local'
          }
        }
      })
    },
    onProcessFileStart() {
      this.isUploading = true; // Disable save button
    },
    onProcessFileAbort() {
      this.checkUploadsStatus(); // Check if uploads were aborted
    },
    onProcessFileError() {
      this.checkUploadsStatus(); // Handle error case
    },
    onProcessFileProgress() {
      // Optional: Use this to update progress if needed
    },
    checkUploadsStatus() {
      // Check if any file is still processing or if there are no files at all
      const hasProcessingFiles = this.$refs.pond.getFiles().some(
        (file) =>{
          file.status === 2 || // FilePond.FileStatus.PROCESSING
          file.status === 1 }// FilePond.FileStatus.PROCESSING_QUEUED
      );


      this.isUploading = hasProcessingFiles || this.$refs.pond.getFiles().length === 0;
    },
    handleUpdateModalClose() {
      this.handleCancel()
      this.showUpdateModal = false
    },
    handleCreateModalClose(){
      this.handleCancel()
      this.showCreateModal = false
    },
    isProcessingFiles() {
      const filesStatuses = this.$refs.pond.getFiles().map(file => file.status);
      return filesStatuses.includes(3) === true || filesStatuses.includes(9);
    },
  },
  computed: {
    ...mapGetters(['announcements','pagination', 'announcementById']),
  },
  created() {
    this.init()
  }
}
</script>

<style scoped>

</style>
